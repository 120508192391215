import React from 'react'
import styles from './bannerFooter.module.scss'
import { Link } from 'gatsby'

type bannerFooterType = {
   mobileBannersFooter: {
      bannerUrl: string;
      altImg: string;
      slug: string;
      name: string;
   }[]
}

const bannerFooter: React.FC<bannerFooterType> = ({ mobileBannersFooter }) => {
   return (
      <div className={styles.divPai}>
         <Link to={mobileBannersFooter[0].slug} className={styles.link}>
            <div className={styles.divs}>
               <img src={mobileBannersFooter[0].bannerUrl} />
               <p className={styles.subtitles}>{mobileBannersFooter[0].name}</p>
            </div>
         </Link>
         <Link to={mobileBannersFooter[1].slug} className={styles.link}>
            <div className={styles.divs}>
               <img src={mobileBannersFooter[1].bannerUrl} />
               <p className={styles.subtitles}>{mobileBannersFooter[1].name}</p>
            </div>
         </Link>
         <Link to={mobileBannersFooter[2].slug} className={styles.link}>
            <div className={styles.divs}>
               <img src={mobileBannersFooter[2].bannerUrl} className={styles.tamanho100} />
               <p className={styles.subtitles}>{mobileBannersFooter[2].name}</p>
            </div>
         </Link>
         <Link to={mobileBannersFooter[3].slug} className={styles.link}>
            <div className={styles.divs}>
               <img src={mobileBannersFooter[3].bannerUrl} />
               <p className={styles.subtitles}>{mobileBannersFooter[3].name}</p>
            </div>
         </Link>
         <Link to={mobileBannersFooter[4].slug} className={styles.link}>
            <div className={styles.divs}>
               <img src={mobileBannersFooter[4].bannerUrl} />
               <p className={styles.subtitles}>{mobileBannersFooter[4].name}</p>
            </div>
         </Link>
      </div>
   )
}

export default bannerFooter
