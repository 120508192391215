import React from "react"
import { Link } from "gatsby"
import ImageComponent from "../ImageComponent/SingleImageComponent"
import AddToWishlist from "../AddToWishlist/AddToWishlist"
import Carousel from "react-slick"
import styles from "./Showcase.module.scss"
import { MAGENTO_URL } from "../../../userglobals"
import RatingStars from "../RatingStars/RatingStars"
import Button from "@material-ui/core/Button"
import ProductCard from '../Category/components/productCard'

type ShowcaseType = {
  id: string
  title: string
  subtitle: string
  products: ShowcaseProductType[]
}

type ShowcaseProductType = {
  id: number
  image: string
  name: string
  price: number
  rating: number | null
  sku: string
  specialPriceIndividual: string
  specialPriceRule: string
  slug: string
}

const Showcase: React.FC<ShowcaseType> = ({
  id,
  title,
  subtitle,
  products,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    initialSlide: -0.8,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    autoplay: false,

  }


  //console.log(id, title, subtitle, products)
  return (
    <div>
      <div className={styles.divTitle}>
        <p className={styles.titleShowcase}>{title}</p>
        <p className={styles.subtitleShowcase}>{subtitle}</p>
      </div>
      <Carousel
        {...settings}
      >
        {products.map(product => {
          return (
            <div key={product.id} className={styles.productCard}>
              <ProductCard product={product} displayMode={'showcase'} />
            </div>
          )
        })}
      </Carousel>

    </div>
  )
}

export default Showcase
