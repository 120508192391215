import React from 'react'
import Layout from '../components/Layout/Layout'
import { PageProps } from "gatsby"
import SEO from '../components/seo'
import Home from '../components/Home/Home'
import { graphql } from "gatsby"

const Index: React.FC<PageProps> = ({ data }) => {
  const { allShowcases, site } = data;

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Virtual Joias",
    "description": site.siteMetadata.description,
    "url": site.siteMetadata.siteUrl,
    "logo": "https://magento-virtualjoias.com/media/logos/logo-vj.png",
    "sameAs": [
      "https://www.facebook.com/virtualjoias/",
      "https://www.instagram.com/virtualjoias/",
      "https://br.pinterest.com/virtualjoias/"
    ]
  }

 

  return (
    <Layout>
      <SEO  schemaMarkup={schema} />
      <Home showcases={allShowcases} />
    </Layout>
  )
}

export const query = graphql`
    query {
      site{
        siteMetadata {
          description
          siteUrl
        }
      }
        allShowcases{
            edges{
              node{
                id
                title
                subtitle
                products{
                  id
                  image
                  name
                  sku
                  rating
                  price
                  specialPriceRule
                  specialPriceIndividual
                  slug
                }
              }
            }
          }
    }
`;

export default Index



