import React from 'react'
import styles from './bannerKits.module.scss'
import { Link } from 'gatsby'

type bannerKitsType = {
   mobileBannersConjuntos: {
      categoryName: string;
      bannerUrl: string;
      altImg: string;
      slug: string;
   }[]
}

const bannerKits: React.FC<bannerKitsType> = ({ mobileBannersConjuntos }) => {
   return (
      <div>
         {
            mobileBannersConjuntos.map((item, index) => {
               return (
                  <Link to={item.slug} key={index}>
                     <div className={styles.divs}>
                        <img
                           src={item.bannerUrl}
                           alt={item.altImg}
                           className={styles.image}
                        />
                        <div className={styles.subtitle}>
                           <p className={styles.categoryName}>{item.categoryName}</p>
                           <p className={styles.verTodos}>VER TODOS</p>
                        </div>
                     </div>
                  </Link>
               )
            })
         }
      </div>
   )
}

export default bannerKits
